import React, { useState } from "react";
import { Spinner } from "@amzn/alchemy-components-react";
import { t } from "src/helpers";
import { useBundle } from '@amzn/react-arb-tools';
import { IMPORTS_AUDIT_BUNDLE_NAME } from 'src/constants';

export const Loader = ({ label, position, visibile, ...props }: { label?: string; position?: string, visibile?: boolean }) => {
	const [bundle] = useBundle(IMPORTS_AUDIT_BUNDLE_NAME);

	return <div className={`flex ${position === 'start' ? 'justify-start' : 'justify-center'} ${visibile ? "visible" : "invisible"}`} {...props}>
		<Spinner label={label || t(bundle, `loading`)} labelPosition='end' />
	</div>
}