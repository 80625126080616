import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Tag } from "@amzn/alchemy-components-react";
import { t } from "src/helpers";
import { IMPORTS_AUDIT_BUNDLE_NAME } from 'src/constants';
import { useBundle } from '@amzn/react-arb-tools';

export const MultiSelect = (props: any) => {
	const [bundle] = useBundle(IMPORTS_AUDIT_BUNDLE_NAME);
	const { options, selected, onSelected } = props;
	const [selectedOptions, setSelectedOptions] = useState(selected || []);
	const [value, setValue] = useState('');
	const transformedOpts = useMemo(() => [...options.map((option: any) => {
		return { ...option, disabled: option.disabled || selectedOptions.includes(option.value) };
	})], [options, selectedOptions]);

	const SITES_DROPDOWN_OPTION_ALL_SITES_OPTION = t(bundle, `imports_audit_all_sites`)

	const getUniqueSelectedOptions = (selectedOpts: any, lastSelectedOption: any) => {
		const uniqueOptions = selectedOpts.filter((value: any, index: any, self: any) => value !== '' && self.indexOf(value) === index);

		if (lastSelectedOption === SITES_DROPDOWN_OPTION_ALL_SITES_OPTION) {
			setSelectedOptions([SITES_DROPDOWN_OPTION_ALL_SITES_OPTION]);
			onSelected && onSelected([SITES_DROPDOWN_OPTION_ALL_SITES_OPTION]);

			return
		} else if (uniqueOptions.includes(SITES_DROPDOWN_OPTION_ALL_SITES_OPTION)) {
			setSelectedOptions([lastSelectedOption]);
			onSelected && onSelected([lastSelectedOption]);

			return
		}

		setSelectedOptions(uniqueOptions);
		setValue('');
		onSelected && onSelected(uniqueOptions);
	}

	const removeOption = (option: any) => {
		const newSelectedOptions = selectedOptions.filter((selected: any) => selected !== option);
		setSelectedOptions(newSelectedOptions);
		onSelected && onSelected(newSelectedOptions);
	}

	useEffect(() => {

	}, [selectedOptions, onSelected]);

	return (
		<div className="inline-flex flex-col justify-stretch align-items-start w-full px-2">
			<Dropdown
				disabled={props.disabled}
				className="mb-2"
				label={props.label}
				data-testid="multi-select-dropdown"
				{...props}
				maxHeight="200px"
				value={value}
				options={transformedOpts || [{value: '', label: ''}]}
				onChange={(e: any) => getUniqueSelectedOptions([...selectedOptions, e?.target?.value], e?.target?.value) }
			>
			</Dropdown>
			<div>
			{selectedOptions.map((selected: any) => {
				const option = options.find((option: any) => option.value === selected);
				if (!option) {
					return
				}
				return (
					<Tag
					data-testid={`multi-select-tag-${option.value}`}
					className="mr-1 mb-1"
					dismissible={true}
					key={option.value}
					size="sm"
					onDismiss={()=>removeOption(option.value)}>
						{option.label}
					</Tag>
				);

			})}
			</div>
		</div>
	);

}