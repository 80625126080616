import React from "react";
import { Icon, Tooltip } from "@amzn/alchemy-components-react";

const UploadStatusCell = (info: any) => {
	const cellValue = info.getValue();

	if (cellValue?.status === "Successful") {
		return (<div className={`${(info.column.getIndex() && !info.cell.getIsGrouped()) ? 'border-l-2' : '' }  my-1 inline-block`} >
		<p className={'min-w-14 h-full m-0 mx-1'}>
			<span className='inline-block w-full'>{cellValue.status}</span>
		</p>
	</div>)
	}

	if (cellValue.statusReason) {
		const trimmedStatusReason = `${cellValue.statusReason.substring(0, 35)}...`;

		return (
			<div className={`${(info.column.getIndex() && !info.cell.getIsGrouped()) ? 'border-l-2' : '' }  my-1 inline-block`} >
				<p className={'min-w-14 h-full m-0 mx-1'}>
					<button onClick={(e) => alert(cellValue.statusReason)}>
						<Tooltip  message={trimmedStatusReason} position="left">
							<span slot="trigger">
								{`${cellValue.status} `}
								<Icon className="justify-center" icon="warning"/>
							</span>
							{trimmedStatusReason}
						</Tooltip>
					</button>
				</p>
			</div>
		)
	}

	return null;
}

export default UploadStatusCell;