import translationar from './translation-ar.json';
import translationbn from './translation-bn.json';
import translationde from './translation-de.json';
import translationen from './translation-en.json';
import translationes from './translation-es.json';
import translationfr from './translation-fr.json';
import translationgu from './translation-gu.json';
import translationhi from './translation-hi.json';
import translationit from './translation-it.json';
import translationja from './translation-ja.json';
import translationkn from './translation-kn.json';
import translationko from './translation-ko.json';
import translationml from './translation-ml.json';
import translationmr from './translation-mr.json';
import translationnl from './translation-nl.json';
import translationpl from './translation-pl.json';
import translationpt from './translation-pt.json';
import translationsv from './translation-sv.json';
import translationta from './translation-ta.json';
import translationte from './translation-te.json';
import translationth from './translation-th.json';
import translationtr from './translation-tr.json';
import translationvi from './translation-vi.json';
import translationzh from './translation-zh.json';
import translation from './translation.json';

// webpack transforms these JSON imports to resource URLs, so we cast our export accordingly here.
const i18nEndPointMap: Record<string, string> = {
  'translation-ar.json': translationar as unknown as string,
  'translation-bn.json': translationbn as unknown as string,
  'translation-de.json': translationde as unknown as string,
  'translation-en.json': translationen as unknown as string,
  'translation-es.json': translationes as unknown as string,
  'translation-fr.json': translationfr as unknown as string,
  'translation-gu.json': translationgu as unknown as string,
  'translation-hi.json': translationhi as unknown as string,
  'translation-it.json': translationit as unknown as string,
  'translation-ja.json': translationja as unknown as string,
  'translation-kn.json': translationkn as unknown as string,
  'translation-ko.json': translationko as unknown as string,
  'translation-ml.json': translationml as unknown as string,
  'translation-mr.json': translationmr as unknown as string,
  'translation-nl.json': translationnl as unknown as string,
  'translation-pl.json': translationpl as unknown as string,
  'translation-pt.json': translationpt as unknown as string,
  'translation-sv.json': translationsv as unknown as string,
  'translation-ta.json': translationta as unknown as string,
  'translation-te.json': translationte as unknown as string,
  'translation-th.json': translationth as unknown as string,
  'translation-tr.json': translationtr as unknown as string,
  'translation-vi.json': translationvi as unknown as string,
  'translation-zh.json': translationzh as unknown as string,
  'translation.json': translation as unknown as string
}

export default i18nEndPointMap;
