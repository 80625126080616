import React, { useEffect, useState } from 'react';
import AuthInterceptor from 'src/services/AuthInterceptor';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useLocalStorage } from 'src/hooks/Core';
import { t } from 'src/helpers';
import { useBundle } from '@amzn/react-arb-tools';
import { IMPORTS_AUDIT_BUNDLE_NAME, WarehouseTypes } from 'src/constants';
import arbManifest from 'translations/arbManifest';
import { resolveBundleUrlWithFallbacks } from '../helpers';
import { MbmProvider } from '@amzn/react-arb-tools';
import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { DEFAULT_LOCALE } from 'src/constants';
import { ImportsAuditProvider } from 'src/hooks/ImportsAudit';
import { SiteImports } from './SiteImports';
import SitesService from 'src/services/SitesService';

export interface ImportsAuditProps {
	planId?: string;
	siteId?: string;
	siteType?: string;
	apiEndpoint?: string;
}

const queryClient = new QueryClient();

const mbmOptions = {
	arbManifest,
	defaultLocalizationContext: new LocalizationContextBuilder()
		.withLocale(DEFAULT_LOCALE)
		.withDefaultLocale(DEFAULT_LOCALE)
		.build(),
	resolveBundleUrl: (
		resourcePackPath: string | undefined,
		locale: string,
		bundle: string
	) => resolveBundleUrlWithFallbacks(resourcePackPath, locale, bundle),
};

export const ImportsAudit = ({ planId = '', siteId = '', siteType = '', apiEndpoint = '' }: ImportsAuditProps) => {
	const [bundle] = useBundle(IMPORTS_AUDIT_BUNDLE_NAME);
	const [siteIds, setSiteIds] = useState<string[]>([siteId]);

    useEffect(() => {
		const { getSitesBySiteType } = SitesService;
		const fetchSites = async () => {
			const { sites } = await getSitesBySiteType(siteType);

			setSiteIds(sites)
		};

		fetchSites();
	}, [])

	return (
		<ImportsAuditProvider siteId={siteId} siteType={siteType}>
			<div data-testid="imports-audit">
				<div className='b-action-background p-2' data-testid="imports-audit-header">
					<h1 className='text-4xl font-bold' data-testid="imports-audit-title">{`${siteId} ${t(bundle, 'imports_audit_title')}`}</h1>
				</div>
				<div data-testid="imports-audit-container">
					<div className='my-2 px-2' data-testid="imports-audit-constraints-metric-view-container-site" >
						<SiteImports siteId={siteId} siteIds={siteIds} siteType={siteType} />
					</div>
				</div>
			</div>
		</ImportsAuditProvider>
	);
};

const ImportsAuditWrapper = ({ siteId, apiEndpoint, ...props }: ImportsAuditProps) => {
	const [authInterceptor, setAuthInterceptor] = useState<AuthInterceptor | null>(null);

	useEffect(() => {
		apiEndpoint && setAuthInterceptor(AuthInterceptor.getInstance(apiEndpoint))
	}, [apiEndpoint]);

	if (!authInterceptor)
		return null;

	return (<QueryClientProvider client={queryClient}>
		<MbmProvider {...mbmOptions}>
			{siteId && <ImportsAudit siteId={siteId} apiEndpoint={apiEndpoint} {...props} /> }
		</MbmProvider>
	</QueryClientProvider>)
}

export default ImportsAuditWrapper;