import { Button, Dropdown } from "@amzn/alchemy-components-react";
import React, { ChangeEvent, useState } from "react";
import { useBundle } from "@amzn/react-arb-tools";

import { IMPORTS_AUDIT_BUNDLE_NAME } from "src/constants";
import { t } from "src/helpers";

interface PagerProps {
	getState: () => {
		pagination: {
			pageSize: number;
			pageIndex?: number;
		};
	};
	setPageSize: (pageSize: number) => void;
	firstPage: () => void;
	previousPage: () => void;
	nextPage: () => void;
	lastPage: () => void;
	getCanPreviousPage: () => boolean;
	getCanNextPage: () => boolean;
	getPageCount?: () => number;
}

export const Pager = (props: PagerProps) => {
	const [bundle] = useBundle(IMPORTS_AUDIT_BUNDLE_NAME);
	const { getState, setPageSize, firstPage, previousPage, nextPage, lastPage, getCanPreviousPage, getCanNextPage, getPageCount } = props;
	const pageSizeOptions = [10, 20, 30, 40, 50];
	const pageSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
		setPageSize(Number(e.target.value))
	}
	const getPageIndex = (pageIndex?: number): number => typeof pageIndex === 'number' ? pageIndex + 1 : 0;

	return (
		<div className='mt-2 gap-1 flex justify-between metric-view-inputs-table-pager' data-testid='metric-view-inputs-table-pager'>
			<div data-testid='imports-audit-table-pager-p`age-size-label'>
				{/* <Todo: Temp option, remove changing page size */}
				<Dropdown
					label='Page Size'
					value={`${getState().pagination.pageSize}`}
					onChange={pageSizeChange}
					onSelect={pageSizeChange}
					options={pageSizeOptions.map(pageSize => ({
						value: pageSize,
						label: `${pageSize}`
					}))}
					data-testid='imports-audit-table-pager-page-size-dropdown'
				/>
			</div>
			<div className='inline-flex gap-1 justify-center align-center'>
				<Button 
					onClick={() => firstPage()}
					disabled={!getCanPreviousPage()}
					data-testid='imports-audit-table-pager-button-first-page'
					icon="chevron-double-left"
					label={t(bundle, `pager_first_page`)}
				/>
				<Button 
					onClick={() => previousPage()}
					disabled={!getCanPreviousPage()}
					data-testid='imports-audit-table-pager-button-previous-page'
					icon="chevron-left"
					label={t(bundle, `pager_previous_page`)}
				/>
				<span className="mx-2 h-2" data-testid='imports-audit-table-pager-page-number'>{`Page ${getPageIndex(getState().pagination.pageIndex)} of ${getPageCount ? getPageCount() : ''}`}</span>
				<Button 
					onClick={() => nextPage()}
					disabled={!getCanNextPage()}
					data-testid='imports-audit-table-pager-button-next-page'
					icon="chevron-right"
					label={t(bundle, `pager_next_page`)}
				/>
				<Button 
					onClick={() => lastPage()}
					disabled={!getCanNextPage()}
					data-testid='imports-audit-table-pager-button-last-page'
					icon="chevron-double-right"
					label={t(bundle, `pager_last_page`)}
				/>
			</div>
		</div>
	);
}