import React from "react";
import { Icon, Link, Tooltip } from "@amzn/alchemy-components-react";

const ImportLinkCell = (info: any) => {
	const cellValue = info.getValue();

	if (cellValue.statusReason) {
		const trimmedStatusReason = `${cellValue.statusReason.substring(0, 35)}...`;

		return (
			<div className={`${(info.column.getIndex() && !info.cell.getIsGrouped()) ? 'border-l-2' : '' }  my-1 inline-block`} >
				<p className={'min-w-28 h-full m-0 mx-1 d-inline-flex justify-content-center'}>
					<button onClick={(e) => alert(cellValue.statusReason)}>
						<Tooltip  message={trimmedStatusReason} position="left">
							<span slot="trigger">
								<Icon className="justify-center" icon="warning"/>
							</span>
							{trimmedStatusReason}
						</Tooltip>
					</button>
				</p>
			</div>
		)
	}

	// For downloads which we don't have currently
	return (
		<div className={`${(info.column.getIndex() && !info.cell.getIsGrouped()) ? 'border-l-2' : '' }  my-1 inline-block`} >
			<p className={'min-w-28 h-full m-0 mx-1 d-inline-flex justify-content-center disabled'}>
				{/* <Link href={cellValue.url}>
					<Icon className="justify-center" icon="download"/>
				</Link> */}
			</p>
		</div>
	)
}

export default ImportLinkCell;