import axios, { AxiosInstance } from "axios";

class RequestsInterceptor {
	private axiosInstance: AxiosInstance;
	constructor() {
		this.axiosInstance = axios.create();
		this.axiosInstance.interceptors.request.use(this.handleRequest);
	}

	 handleRequest = async (config: any) => {
		return config;
	};

	getAxiosInstance = () => this.axiosInstance;
}

export default new RequestsInterceptor().getAxiosInstance();