import { addMonths, compareAsc} from "date-fns";

import { ImportsRawDataPoint } from "src/types/ImportsAuditInterfaces";
import {
  getUserReadablePropertiesByDatasetCid,
} from "src/utils/DataTransformationUtils";

import {
  AMZL_SITES,
  FC_SITES,
  AB_DATASETCIDS,
  AB_DOMAIN_IDS,
  PROVIDER_IDS,
  STATUSES,
  STATUS_REASONS,
} from "src/constants/generatorConstants"

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, 
          v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

export function generateMockData(
  numRows: number, 
  siteIds: string[], 
  startDate: any, 
  endDate: any,
  datasetClassifications: string[] = AB_DATASETCIDS,
  domainIds: string[] = AB_DOMAIN_IDS,
): ImportsRawDataPoint[] {
    const siteIdOptions = siteIds.includes("All Sites") ? AMZL_SITES : siteIds;
    const mockData = [];
  
    for (let i = 0; i < numRows; i++) {
      const row: ImportsRawDataPoint = {
        scope: {
          type: "SITE",
          value: siteIdOptions[Math.floor(Math.random() * siteIdOptions.length)],
        },
        domainId: domainIds[Math.floor(Math.random() * domainIds.length)],
        sourceTime: getRandomTimestamp(startDate, endDate),
        datasetClassificationId: [datasetClassifications[Math.floor(Math.random() * datasetClassifications.length)]],
        lineage: [
          {
            providerId: PROVIDER_IDS[Math.floor(Math.random() * PROVIDER_IDS.length)],
            timestamp: getRandomTimestamp(startDate, endDate),
          },
          {
            providerId: PROVIDER_IDS[Math.floor(Math.random() * PROVIDER_IDS.length)],
            timestamp: getRandomTimestamp(startDate, endDate),
          }
        ],
        status: STATUSES[Math.floor(Math.random() * STATUSES.length)],
        statusReason: STATUS_REASONS[Math.floor(Math.random() * STATUS_REASONS.length)],
        sourceId: "string",
        messageId: "string",
        transformTime: getRandomTimestamp(startDate, endDate),
        outputS3Path: {
          bucketName: "",
          key: "",
        },
        archiveS3Path: {
          bucketName: "",
          key: "",
        },
      };
  
      mockData.push(row);
    }
    return mockData;
  }

  function getRandomTimestamp(start: Date, end: Date): number {
    const startTime = start.getTime();
    const endTime = end.getTime();
    const randomTime = Math.floor(Math.random() * (endTime - startTime + 1)) + startTime;
    return randomTime;
  }
  
  let prevSiteIds = FC_SITES;
  let prevEndDate: Date;
  let prevStartDate: Date;
  let data = generateMockData(1000, FC_SITES, addMonths(new Date(), -1), new Date())
  let filteredData: ImportsRawDataPoint[] = [];

  export async function fetchData(
    siteIds: string[],
    datasetCids: string[],
    domainIds: string[],
    uploadedByFilter: string,
    startDate: Date,
    endDate: Date,
    options: {
      pageIndex: number;
      pageSize: number;
  }) {
    // Simulate some network latency
    await new Promise((r) => setTimeout(r, 500));

    if (
        !isDeepEqual(siteIds, prevSiteIds) || 
        compareAsc(startDate, prevStartDate) !== 0 || 
        compareAsc(endDate, prevEndDate) !== 0
      ) {
      data = generateMockData(1000, siteIds, startDate, endDate);
      prevSiteIds = siteIds;
    }

    filteredData = data.filter((row: ImportsRawDataPoint) => {
      const {
        datasetCid,
        uploadedBy,
      } = getUserReadablePropertiesByDatasetCid(row.datasetClassificationId[0])

      return (
        (datasetCids.length > 0 ? datasetCids.includes(datasetCid) : true) &&
        (domainIds.length > 0 ? domainIds.includes(row.domainId) : true) &&
        (uploadedByFilter && uploadedBy ? uploadedBy.includes(uploadedByFilter) : true)
      )
    });

    return {
      dataPoints: filteredData.slice(
        options.pageIndex * options.pageSize,
        (options.pageIndex + 1) * options.pageSize
      ),
      pageCount: Math.ceil(filteredData.length / options.pageSize),
      rowCount: filteredData.length,
    };
  }
  
  function isDeepEqual(arr1: string[], arr2: string[]) {
    return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
  }  