import React from 'react';
import { MbmProvider } from '@amzn/react-arb-tools';
import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { API_ENDPOINTS, DEFAULT_LOCALE, WarehouseTypes } from 'src/constants';

import {
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'
import arbManifest from 'translations/arbManifest';
import { resolveBundleUrlWithFallbacks } from '../helpers';
import { Topbar } from '@amzn/alchemy-components-react';
import ImportsAudit from './ImportsAudit';
import { ImportsPageTopBar } from './ImportsPageTopBar';
import { useUserInfo } from 'src/hooks/User';
import { useLocalStorage } from 'src/hooks/Core';

// Create a client
const queryClient = new QueryClient()

const mbmOptions = {
	arbManifest,
	defaultLocalizationContext: new LocalizationContextBuilder()
		.withLocale(DEFAULT_LOCALE)
		.withDefaultLocale(DEFAULT_LOCALE)
		.build(),
	resolveBundleUrl: (
		resourcePackPath: string | undefined,
		locale: string,
		bundle: string
	) => resolveBundleUrlWithFallbacks(resourcePackPath, locale, bundle),
};

const App = () => {
	const userInfo = useUserInfo();
	const [selectedSite, setSelectedSite] = useLocalStorage('inCen_selectedSite', '');

	return (
		// Provide the client to your App
		<QueryClientProvider client={queryClient}>
			<MbmProvider {...mbmOptions}>
					<ImportsPageTopBar />
					<ImportsAudit
						siteType={WarehouseTypes.FULFILLMENT_CENTER}
						siteId={selectedSite}
						apiEndpoint={API_ENDPOINTS.BETA}
						data-testid="app-imports-audit"
					/>
			</MbmProvider>
		</QueryClientProvider>
	);
};

export default App;
