import { aggregationFns } from "@tanstack/react-table";

export const DEFAULT_LOCALE = "en-US";

export const HOMEPAGE_MESSAGE_BUNDLE_NAME = "pages.homepage";
export const IMPORTS_AUDIT_BUNDLE_NAME = "pages.imports_audit";

export const DEFAULT_PAGE_NUMBER = 0;
export const DEFAULT_PAGE_SIZE = 50;

export const API_ENDPOINTS = {
  BETA: "https://alps-beta-iad.iad.proxy.amazon.com",
};

export const WarehouseTypes = {
	AMAZON_LOGISTICS: "AMAZON_LOGISTICS",
	FULFILLMENT_CENTER: "FULFILLMENT_CENTER",
	SORT_CENTER: "SORT_CENTER",
	AMAZON_AIR: "AMAZON_AIR"
};

export const DatasetClassificationOptions = [
	{ value: 'Manual Baseline', label: "Manual Baseline"},
	{ value: 'Auto Baseline', label: "Auto Baseline"},
	{ value: 'Central Guidance', label: "Central Guidance"},

	// Milestone 2
	// { value: 'Plan Overrides', label: "Plan Overrides", disabled: true }
];

// Todo: Consider mapping by LoB
export const MetricNameOptions = [
	'activity_time',
	'attendance_forecast',
	'attrition_forecast',
	'auto_rate',
	'flow_path_percentage_forecast',
	'labor_order_status',
	'operation_split_curve',
	'ready_hours',
	'transfers_input',
	'veteran_rate_forecast',
	'volume_forecast',
]

export const StatusOptions = [
	{ value: undefined, label: "Any"},
	{ value: 'TRANSFORMED', label: "Successful"},
	{ value: 'FAILED', label: "Failed"},
];

// Todo: Use panther translation constants
export const ImportsAuditHeaders = [
	{
		label: "Site",
		id: "site",
		accessorKey: "site",
	},
	{
		label: "Dataset Classification",
		id: "datasetCid",
		accessorKey: "datasetCid",
	},
	{
		label: "Metric Name",
		id: "metricName",
		accessorKey: "metricName",
	},
	{
		label: "Uploaded By",
		id: "uploadedBy",
		accessorKey: "uploadedBy",
	},
	{
		label: "Uploaded At",
		id: "uploadedAt",
		accessorKey: "uploadedAt",
	},

	// Milestone 2 columns for Site ID page.
	{
		label: "Last Plan ID",
		id: "lastPlanId",
		accessorKey: "lastPlanId",
	},
	{
		label: "Last Plan Date",
		id: "lastPlanDate",
		accessorKey: "lastPlanDate",
	},

	{
		label: "Upload Status",
		id: "uploadStatus",
		accessorKey: "uploadStatus",
	},
	// {
	// 	label: "Data",
	// 	id: "linkToRawData",
	// 	accessorKey: "mock
	// },
];

export const TimeframeColumns = 7;