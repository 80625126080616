export function upperSnakeCaseToTitleCase(str: string) {
  const words = str.split("_");

  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  const titleCase = capitalizedWords.join(" ");

  return titleCase;
}

export function titleCaseToUppserSnakeCase(str: string) {
    const words = str.split(" ");
  
    const snakeCase = words
      .map((word) => word.toUpperCase())
      .join("_");
  
    return snakeCase;
}
