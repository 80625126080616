import { Button, Datepicker, Input, Dropdown } from "@amzn/alchemy-components-react";
import { useBundle } from "@amzn/react-arb-tools";
import React, { useState } from "react";
import { addDays, differenceInDays, compareAsc, subDays, endOfDay } from 'date-fns'

import { MultiSelect } from "./Wrapped/MultiSelect";
import { t } from "src/helpers";
import { IMPORTS_AUDIT_BUNDLE_NAME, MetricNameOptions, DatasetClassificationOptions, StatusOptions } from 'src/constants';
import { SiteImportsQueryProps } from "src/types/ImportsAuditInterfaces";
import { upperSnakeCaseToTitleCase } from "src/utils/StringUtils";

interface ImportsAuditFormProps {
	siteIds: string[];
    onApplyClick: Function;
	importsQueryParams: SiteImportsQueryProps;
	siteType: string;
}

const maxDateRangeDays = 30;
const importsAuditProdRelease = new Date(2024, 8, 1);

// TODO: Renders Forms, filters, pagination, buttons
export const ImportsAuditForm = ({ 
    siteIds,
    onApplyClick,
    importsQueryParams,
    siteType,
}: ImportsAuditFormProps) => {
	const [bundle] = useBundle(IMPORTS_AUDIT_BUNDLE_NAME);

    const [multiSelectSiteIds, setMultiSelectSiteIds] = useState<string[]>(importsQueryParams.siteIds);
	const [datasetCids, setDatasetCids] = useState<string[]>(importsQueryParams.datasetCids);
	const [domainIds, setDomainIds] = useState<string[]>(importsQueryParams.domainIds);
	const [status, setStatus] = useState<any>(importsQueryParams.status);
	const [uploadedBy, setUploadedBy] = useState(importsQueryParams.uploadedBy);
    const metricNameOptionsValues = MetricNameOptions.map((metricName) => ({ value: metricName, label: upperSnakeCaseToTitleCase(metricName) }))

    const allSitesLabel = bundle ? t(bundle, `imports_audit_all_sites`) : "All Sites";
    const selectOptions = bundle ? t(bundle, `imports_audit_select_options`) : "Select Options";

    const defaultOption = { value: '', label: selectOptions, disabled: true };
    const allSitesOption = { value: allSitesLabel, label: allSitesLabel };
    const siteOptions = siteIds.map((siteId) => ({ value: siteId, label: siteId}))

    const [startDate, setStartDate] = useState(importsQueryParams.startDate);
	const [endDate, setEndDate] = useState(importsQueryParams.endDate);
    const maxEndDate = endOfDay(Date.now());

    const handleStartDateChange = (date: Date) => {
        if (compareAsc(date, endDate) === 1) {
            setEndDate(date)
        }

        if (Math.abs(differenceInDays(date, endDate)) > maxDateRangeDays) {
            setEndDate(addDays(date, maxDateRangeDays))
        }

        setStartDate(date)
    }

    const handleEndDateChange = (date: Date) => {
        const endOfSelectedDate = endOfDay(date);

        if (compareAsc(startDate, endOfSelectedDate) === 1) {
            setStartDate(endOfSelectedDate)
        }

        if (Math.abs(differenceInDays(startDate, endOfSelectedDate)) > maxDateRangeDays) {
            setStartDate(subDays(endOfSelectedDate, maxDateRangeDays))
        }

        setEndDate(endOfSelectedDate)
    }

    const disabledDates = {
        ranges: [
            {
                from: new Date(1970, 1, 1),
                to: importsAuditProdRelease
            }, 
            {
                from: new Date(maxEndDate),
                to: new Date(2038, 12, 12)
            }
    ],
    };

    return (
        <div>
            <div className='my-2 flex justify-between'>
                <MultiSelect
                    label={t(bundle, `imports_audit_form_site_ids`)}
                    options={[defaultOption, allSitesOption, ...siteOptions]}
                    selected={multiSelectSiteIds}
                    onSelected={setMultiSelectSiteIds} 
                />
                <MultiSelect
                    label={t(bundle, `imports_audit_form_dataset_cid`)}
                    options={[defaultOption, ...DatasetClassificationOptions]}
                    selected={datasetCids}
                    onSelected={setDatasetCids}
                />
                <MultiSelect
                    label={t(bundle, `imports_audit_form_metric_name`)}
                    options={[defaultOption, ...metricNameOptionsValues]}
                    selected={domainIds}
                    onSelected={setDomainIds}
                />
                <Dropdown
                    label={t(bundle, `imports_audit_form_status`)}
                    maxHeight="200px"
                    value={status}
                    options={StatusOptions}
                    onChange={(e: any) => {
                        console.log(e?.target?.value)
                        setStatus(e?.target?.value)
                    }}
                    />
                <Input
                    className="inline-flex flex-col justify-stretch align-items-start w-full px-2"
                    label={t(bundle, `imports_audit_form_uploaded_by`)}
                    placeholder={t(bundle, `imports_audit_form_uploaded_by`)}
                    value={uploadedBy}
                    onInput={(e) => {
                        setUploadedBy(e.target.value)
                    }}  
                    search
                />
            </div>
            <div className='my-2 flex'>
                <Datepicker
                    className="px-2"
                    label={t(bundle, `imports_audit_form_start_date`)}
                    value={startDate.toDateString()}
                    useUtc={false}
                    highlighted={{ from: new Date(startDate), to: new Date(endDate) }}
                    onSelected={(event) => {
                        handleStartDateChange(event.detail)
                    }}
                    disabledDates={disabledDates}
                    typeable={false}
                    maximumView="month"
                />
                <Datepicker
                    className="px-2"
                    label={t(bundle, `imports_audit_form_end_date`)}
                    // language={getShortenedLocale(true)} // TODO: if locale not supported, component will not load; Alchemy team is aware
                    value={endDate.toDateString()}
                    useUtc={false}
                    highlighted={{ from: new Date(startDate), to: new Date(endDate) }}
                    onSelected={(event) => {
                        handleEndDateChange(event.detail)
                    }}
                    disabledDates={disabledDates}
                    typeable={false}
                    maximumView="month"
                />
            </div>
            <div className='flex justify-between py-2'>
                <Button
                    icon='refresh'
                    className='px-2'
                    variant='primary'
                    onClick={() => {
                        onApplyClick({
                          siteIds: multiSelectSiteIds,
                          datasetCids,
                          domainIds,
                          uploadedBy,
                          startDate,
                          endDate,
                          status,
                          siteType
                        });
                    }}
                    data-testid="imports-audit-form-generate-report-button"
                >
                    {t(bundle, `imports_audit_form_generate_report`)}
                </Button>
            </div>
        </div>
    )
}