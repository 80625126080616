import axios from "./AxiosInstance";
import './AuthInterceptor';

class SitesService {
  constructor() {}

  async getSitesBySiteType(id: string) {
    const response = await axios.get(
      `/sites/${id}`
    );

    return response.data;
  }
}

export default new SitesService();
