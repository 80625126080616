import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
	GroupingState,
	useReactTable,
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	getExpandedRowModel,
	getGroupedRowModel,
	getFilteredRowModel,
	PaginationState,
	OnChangeFn,
} from '@tanstack/react-table'
import { Alert, Icon } from "@amzn/alchemy-components-react";
import { useBundle } from "@amzn/react-arb-tools";
import { IMPORTS_AUDIT_BUNDLE_NAME } from "src/constants";
import { t } from "src/helpers";
import { TextCell, HeaderTextCell, UploadStatusCell } from './Cells';
import { useImportsAuditContext, useSiteImportsData } from 'src/hooks/ImportsAudit';
import { Loader } from 'src/components/Wrapped/Loader';
import { Pager } from './Pager';
import { 
	ImportsAuditHeaders,
 } from 'src/constants'

import { SiteImportsQueryParams, SiteImportsQueryProps } from "src/types/ImportsAuditInterfaces";

interface ImportsAuditTableProps {
	importsQueryParams: SiteImportsQueryProps;
	setPagination: OnChangeFn<PaginationState>;
	pagination: PaginationState,
}

export const ImportsAuditTable = ({ 
	importsQueryParams,
	setPagination,
	pagination,
}: ImportsAuditTableProps) => {
	const [bundle] = useBundle(IMPORTS_AUDIT_BUNDLE_NAME);
	const { timeZone, siteImportsDataRefetchRef } = useImportsAuditContext();

	const importQueryParamsFixed: SiteImportsQueryParams = useMemo(() => ({
		...importsQueryParams,
		pagination,
	  }), [importsQueryParams, pagination]);
	  
	const { importsData = { dataPoints: [], pageCount: 1, }, isLoading: isSiteImportsLoading, isError: isSiteImportsError, refetch: refetchMetricData, ...otherImportsDataDebug } = useSiteImportsData(importQueryParamsFixed);
	siteImportsDataRefetchRef.current = refetchMetricData

	const columns: any[] = [
		...ImportsAuditHeaders.map((header: { label: string; id: string; accessorKey: string; }) => ({
			header: <HeaderTextCell text={header.label} />,
			id: header.id,
			accessorKey: header.accessorKey,
			// cell: header.id === "linkToRawData" ? <Icon icon="download" /> : TextCell,
			// cell: header.id === "linkToRawData" ? ImportLinkCell : TextCell, // For Milestone 2
			cell: header.id === "uploadStatus" ? UploadStatusCell : TextCell,
			meta: () => ({ header }),
			filterFn: 'arrIncludesSome'
		})).filter(col => col.id !== 'cycle'),
	];

	const {
		getHeaderGroups,
		getRowModel,
		...tableProps
	} = useReactTable({
		data: importsData.dataPoints,
		columns: columns.filter((x) => (x.id !== "lastPlanId" && x.id !== "lastPlanDate")),
		pageCount: importsData.pageCount,
		manualPagination: true, //we're doing manual "server-side" pagination
		state: {
			pagination,
		},
		getCoreRowModel: getCoreRowModel(),
		onPaginationChange: setPagination,
		getExpandedRowModel: getExpandedRowModel(),
		getGroupedRowModel: getGroupedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
	})

	if (isSiteImportsLoading) {
		return <Loader data-testid="metric-view-inputs-table-loader" />
	}

	if (isSiteImportsError) {
		return (
			<div data-testid="metric-view-inputs-table-error">
				<Alert type='error' icon='warning' header={t(bundle, `site_imports_alerts_data_error_header`)} message={t(bundle, `site_imports_alerts_data_error_message`)} />
			</div>
		);
	}

	return (
		<div className='overflow-auto flex justify-center flex-col imports-audit-table-container' data-testid='imports-audit-table-container' >
			<Loader data-testid="metric-view-inputs-table-loader" visibile={otherImportsDataDebug.isFetching} />
			<div className='flex justify-center imports-audit-table' data-testid='imports-audit-table'>
				<table className='w-full'>
					<thead>
						{getHeaderGroups().map(headerGroup => (
							<tr key={headerGroup.id} className='border-y-2'>
								{headerGroup.headers.map(header => (
									<th key={header.id} className=''>
										{header.isPlaceholder
											? null
											: flexRender(
												header.column.columnDef.header,
												header.getContext()
											)}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody >
						{getRowModel().rows.length === 0 && <tr>
							<td colSpan={columns.length} className='text-center'>
								<Alert className='my-2' type='info' icon='info' header={t(bundle, `imports_audit_alerts_no_data_header`)} message={t(bundle, `imports_audit_alerts_no_data_message`)} />
							</td>
						</tr>}
						{getRowModel().rows.map(row => {
							return (
								<tr key={row.id} className='border-b-2'>
									{row.getVisibleCells().map((cell, cellIdx) => (
										<td key={cell.id} className=''>
											{cell.getIsGrouped() ? (
												// If it's a grouped cell, add an expander and row count
												<>
													<button
														className='inline-block'
														{...{
															onClick: row.getToggleExpandedHandler(),
															style: {
																cursor: row.getCanExpand()
																	? 'pointer'
																	: 'normal',
															},
														}}
													>
														{row.getIsExpanded() ? <Icon icon='chevron-down' /> : <Icon icon='chevron-right' />}{' '}

													</button>
													{flexRender(
														cell.column.columnDef.cell,
														cell.getContext()
													)}
												</>
											) : cell.getIsAggregated() ? (
												// If the cell is aggregated, use the Aggregated
												// renderer for cell
												flexRender(
													cell.column.columnDef.aggregatedCell ??
													cell.column.columnDef.cell,
													cell.getContext()
												)
											) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
												// Otherwise, just render the regular cell
												flexRender(
													cell.column.columnDef.cell,
													cell.getContext()
												)
											)}
										</td>
									))}
								</tr>
							)
						}
						)}
					</tbody>
				</table>
			</div>
			<Pager {...tableProps} />
		</div>
	);
}