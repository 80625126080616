import axios from "./AxiosInstance";
import FetchClient from "./FetchClient";

class AuthInterceptor {
	private static instance: AuthInterceptor;
	private fetchClient: FetchClient;
	private interceptorId: number;

	private constructor(endpoint: string) {
		if (!endpoint) {
			throw new Error("Endpoint is required");
		}
		this.fetchClient = new FetchClient({ endpoint });
		this.interceptorId = axios.interceptors.request.use(
			this.handleRequest,
			function (error) {
				// Do something with request error
				return Promise.reject(error);
			}
		);
	}

	handleRequest = async (config: any) => {
		// if (!config.baseURL || config.baseURL === window.location.origin) {
		// 	return Promise.reject(new Error('Request to origin URL rejected'));
		// }

		if (!AuthInterceptor.instance.fetchClient.endpoint) {
			return Promise.reject(new Error("Endpoint is required"));
		}

		if (!AuthInterceptor.instance.fetchClient.isAuthenticated()) {
			await AuthInterceptor.instance.fetchClient.authenticateWithMidway();
		}

		return {
			...config,
			baseURL: `${AuthInterceptor.instance.fetchClient.endpoint}/api`,
			credentials: 'include',
			withCredentials: true,
			mode: 'cors',
			cache: 'no-cache'
		};

	}
	public static getInstance(endpoint: string): AuthInterceptor {
		if (!AuthInterceptor.instance || AuthInterceptor.instance.fetchClient.endpoint !== endpoint) {
			if (AuthInterceptor.instance) {
				axios.interceptors.request.eject(AuthInterceptor.instance.interceptorId);
			}
			AuthInterceptor.instance = new AuthInterceptor(endpoint);
		}
		return AuthInterceptor.instance;
	}
}

export default AuthInterceptor;
