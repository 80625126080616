
// A clock skew to use for considering a token valid.
const CLOCK_SKEW = 5 * 60 * 1000;
const SESSION_STORAGE_KEY_PRFIX = "alps:midway-auth: ";
const MIDWAY_SSO_LOGIN_SUFFIX = "/sso/login";

interface FetchOptions {
	credentials?: RequestCredentials;
}

interface AuthenticationResponse {
	is_authenticated: boolean;
	authn_endpoint: string;
	expires_at?: number;
}

interface FetchClientOptions {
	endpoint: string;
}

class FetchClient {
	public endpoint: string;
	private fetchMethod: typeof window.fetch = window.fetch;

	constructor(options: FetchClientOptions) {
		this.endpoint = options.endpoint;
		this.fetchMethod = this.fetchMethod?.bind(this);
	}

	public getSessionStorageKey(): string {
		return `${SESSION_STORAGE_KEY_PRFIX}${this.endpoint}`;
	}

	private setExpiry(expiryTime: number): void {
		if (isNaN(expiryTime)) {
			return;
		}
		sessionStorage.setItem(
			this.getSessionStorageKey(),
			(expiryTime - CLOCK_SKEW).toString()
		);
	}

	public isAuthenticated(): boolean {
		const expiryTime = parseInt(
			sessionStorage.getItem(this.getSessionStorageKey()) ?? "",
			10
		);
		return !isNaN(expiryTime) && new Date().getTime() < expiryTime;
	}

	public async makeFetchRequest(url: string): Promise<Response> {
		return window.fetch(url, { credentials: "include" });
	}

	public async authenticateWithMidway(token?: string): Promise<void> {
		let authenticationURL = this.endpoint + MIDWAY_SSO_LOGIN_SUFFIX;
		if (token) {
			authenticationURL = authenticationURL + "?id_token=" + token;
		}
		const response = await this.makeFetchRequest(authenticationURL);
		const responseJSON: AuthenticationResponse = await response.json();
		if (!responseJSON.is_authenticated) {
			const authToken = await this.getMidwayToken(responseJSON.authn_endpoint);
			if (authToken) {
				return this.authenticateWithMidway(authToken);
			}
		}
		if (responseJSON.expires_at) {
			this.setExpiry(responseJSON.expires_at);
		}
	}

	public async getMidwayToken(midwayURL: string): Promise<string | undefined> {
		const midwayResponse = await this.makeFetchRequest(midwayURL);
		if (midwayResponse.status !== 200) return;
		const authToken = await midwayResponse.text();
		return authToken;
	}

	public async fetch(url: string, fetchOptions: FetchOptions = { credentials: "include" }): Promise<Response> {
		if (!this.isAuthenticated()) {
			await this.authenticateWithMidway();
		}
		return window.fetch(url, fetchOptions);
	}
}

export default FetchClient;
