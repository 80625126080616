import React, { useContext, createContext, useRef, useState } from "react";
import ImportsAuditService from "src/services/ImportsAuditService";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { ImportsDataset, SiteImportsQueryParams } from "src/types/ImportsAuditInterfaces";

interface ImportsAuditContextType {
	siteId: string;
	siteType: string;
	timeZone: string;
	setTimeZone: (timeZone: string) => void;
	siteImportsDataRefetchRef: React.MutableRefObject<() => void>;
}

interface UseSiteImportsDataProps {
	startDate: Date;
	endDate?: Date;
	planId: string;
	siteId?: string;
	metricId?: string;
	lpDefId?: string;
	timeZone?: string;
}

export const ImportsAuditContext = createContext<ImportsAuditContextType>({
	siteId: '',
	siteType: '',
	timeZone: '',
	setTimeZone: (timeZone: string) => { },
	siteImportsDataRefetchRef: { current: () => { } }
});

export const useImportsAuditContext = () => {
	return useContext(ImportsAuditContext);
}

export const ImportsAuditProvider = ({ children, siteId, siteType }: { children: React.ReactNode, siteId: string, siteType: string }) => {
	const [timeZone, setTimeZone] = useState<string>('');
	const siteImportsDataRefetchRef = useRef<() => void>(() => {});

	return (<ImportsAuditContext.Provider value={{ siteId, siteType, timeZone, setTimeZone, siteImportsDataRefetchRef }} >
	{children}
	</ImportsAuditContext.Provider>);
}


export const useSiteImportsData = ({ siteIds, datasetCids, domainIds, uploadedBy, startDate, endDate, status, pagination, siteType }: SiteImportsQueryParams) => {
	const { getSitesImports } = ImportsAuditService;

	const { data, ...rest } = useQuery<ImportsDataset, Error>({
		queryKey: ["siteImports", siteIds, datasetCids, domainIds, uploadedBy, startDate, endDate, status, pagination, siteType],
		queryFn: async () => {
			try {
				const reqPayload = {
					siteIds, datasetCids, domainIds, uploadedBy, startDate, endDate, status, pagination, siteType
				}
				const response = await getSitesImports(reqPayload);

				const importsDataset: ImportsDataset = {
					dataPoints: response.dataPoints || [],
					pageCount: response.pageCount,
				};
		  
				return importsDataset;
			} catch (error) {
				throw new Error('Failed to fetch metric data');
			}
		},
		enabled: !!siteIds,
		retry: false,
		placeholderData: keepPreviousData, // don't have 0 rows flash while changing pages/loading next page
	});

	return { importsData: data, ...rest };
};
