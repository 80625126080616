import React from "react";

const TextCell = (info: any) => {
	return (<div className={`${(info.column.getIndex() && !info.cell.getIsGrouped()) ? 'border-l-2' : '' }  my-1 inline-block`} >
		<p className={'min-w-14 h-full m-0 mx-1'}>
			<span className='inline-block w-full'>{info.getValue()}</span>
		</p>
	</div>)
}

export default TextCell;
