import { useEffect, useState } from 'react';
import UserService from 'src/services/UserService';

export const useUserInfo = () => {
	const [userInfo, setUserInfo] = useState<{ username?: string }>({});

	useEffect(() => {
		const fetchUserInfo = async () => {
			const response = await UserService.getUserInfo();
			setUserInfo(response.data);
		};

		fetchUserInfo();
	}, []);

	return userInfo;
};
