import axios from "./AxiosInstance";
import './AuthInterceptor';
import { fetchData } from "../components/mocks/site_imports_generator"
import { SiteImportsQueryParams, Lineage } from "src/types/ImportsAuditInterfaces";
import { getUserFriendlyStrings, getAutobahnDatasetCidsByUserReadble } from "src/utils/DataTransformationUtils";

interface GetSiteImportsProps {
  planId?: string;
  domainId?: string;
  siteId?: string;
  lpDef?: string;
  effectiveTimeStart?: number;
  effectiveTimeEnd?: number;
  skipIndex?: number;
  takeIndex?: number;
  timeZone?: string;
}

function constructScopes(siteIds: string[]) {
  return siteIds.map((siteId) => ({ type: "SITE", value: siteId }));
}

class ImportsAuditService {
  constructor() {}

  async getMockSitesImports(
    {
      siteIds, datasetCids, domainIds, uploadedBy, startDate, endDate, pagination
    }: SiteImportsQueryParams,
  ) {
    const mockResponse = await fetchData(siteIds, datasetCids, domainIds, uploadedBy, startDate, endDate, pagination);

    const filteredDataPoints = getUserFriendlyStrings(mockResponse.dataPoints)

    return Promise.resolve({
      ...mockResponse, 
      dataPoints: filteredDataPoints
    });
  }

  // Todo: Consider adding caching
  async getSitesImports(
    {
      siteIds, datasetCids, domainIds, uploadedBy, startDate, endDate, status, pagination, siteType
    }: SiteImportsQueryParams,
  ) {
    const params = new URLSearchParams();
    params.append('scopes', JSON.stringify(constructScopes(siteIds)));
    params.append('limit', pagination.pageSize.toString());
    params.append('offset', (pagination.pageIndex * pagination.pageSize).toString());
    params.append('horizonStartTime', startDate.getTime().toString());
    params.append('horizonEndTime', endDate.getTime().toString());

    domainIds.length > 0 && params.append('domainIds', domainIds.join(","));
    datasetCids.length > 0 && params.append('datasetClassificationIds', getAutobahnDatasetCidsByUserReadble(datasetCids).join(","));
    uploadedBy && params.append('lineage', uploadedBy);
    status && params.append('ingestionStatus', status);

    if (siteIds.includes("All Sites")) {
      params.append('siteType', siteType);
      params.append('isAllSitesRequest', "true");
    }

    const response = await axios.get(
      '/ingestion_audit',
      { params }
    );

    const filteredDataPoints = getUserFriendlyStrings(response.data.records)

    return Promise.resolve({
      // ...mockResponse, 
      dataPoints: filteredDataPoints,
      pageCount: 99, // Todo: not yet implemented in backend.
    });
  }
}

export default new ImportsAuditService();
