import React, { useState, useEffect } from 'react';

export const useLocalStorage = (key: string, defaultValue: any) => {

	const getCurrentValue = () => {
		let currentValue;

		try {
			currentValue = JSON.parse(
				localStorage.getItem(key) || String(defaultValue)
			);
		} catch (error) {
			currentValue = defaultValue;
		}

		// Todo: Rely on site selector instead of static value
		if (!currentValue) {
			currentValue = "IND1"
		}

		return currentValue;
	}

	const [value, setValue] = useState(getCurrentValue);

	useEffect(() => {
		const strValue = JSON.stringify(value);
		if(strValue === '""') {
			localStorage.removeItem(key);
			return
		}
		localStorage.setItem(key, JSON.stringify(value));
	}, [value]);

	useEffect(() => {
		setValue(getCurrentValue());
	}, [key]);

	return [value, setValue];
};
