import { useBundle } from "@amzn/react-arb-tools";
import React, { useState } from "react";
import { addMonths } from "date-fns";
import { t } from "src/helpers";
import { Topbar } from '@amzn/alchemy-components-react';

import { IMPORTS_AUDIT_BUNDLE_NAME } from "src/constants";

export const ImportsPageTopBar = () => {
	const [bundle] = useBundle(IMPORTS_AUDIT_BUNDLE_NAME);

	return (
    <div>
        <Topbar header={t(bundle, `imports_audit_title`)} className="mb-1" data-testid="app-topbar" />
    </div>
    )
}