import { useBundle } from "@amzn/react-arb-tools";
import React, { useState } from "react";
import { addDays } from "date-fns";
import {
	PaginationState,
} from '@tanstack/react-table'

import { ImportsAuditTable } from "./ImportsAudit/ImportsAuditTable";
import { ImportsAuditForm } from "./ImportsAuditForm"
import { SiteImportsQueryParams, SiteImportsQueryProps } from "src/types/ImportsAuditInterfaces";
import { 
	IMPORTS_AUDIT_BUNDLE_NAME,
	DEFAULT_PAGE_NUMBER,
	DEFAULT_PAGE_SIZE,
 } from 'src/constants'

interface SiteImportsProps {
	siteId: string;
	siteIds: string[];
    siteType: string,
}

export const SiteImports = ({ siteId, siteIds, siteType }: SiteImportsProps) => {
	const [bundle] = useBundle(IMPORTS_AUDIT_BUNDLE_NAME);

    const [importsQueryParams, setImportsQueryParams] = useState<SiteImportsQueryProps>({
        siteIds: [siteId],
        datasetCids: [],
        domainIds: [],
        uploadedBy: "",
        startDate: addDays(new Date(), -29),
        endDate: new Date(),
        siteType,
    })
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: DEFAULT_PAGE_NUMBER,
		pageSize: DEFAULT_PAGE_SIZE,
	});
    
    const handleApplyClick = (siteImportsQueryParams: SiteImportsQueryParams) => {
        setImportsQueryParams(siteImportsQueryParams);
        setPagination({
            pageIndex: DEFAULT_PAGE_NUMBER,
		    pageSize: DEFAULT_PAGE_SIZE,
        });
	};

	return (
        <div>
            <ImportsAuditForm 
                onApplyClick={handleApplyClick}
                siteIds={siteIds}
                importsQueryParams={importsQueryParams}
                siteType={siteType}
            />
            <ImportsAuditTable
                importsQueryParams={importsQueryParams}
                setPagination={setPagination}
                pagination={pagination}
            />
        </div>
    )
}