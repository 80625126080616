import { format } from 'date-fns';

import {upperSnakeCaseToTitleCase } from "src/utils/StringUtils"
import { ImportsRawDataPoint, ImportsDataPoint, Lineage} from "src/types/ImportsAuditInterfaces";

const userReadableDatasetCidsToAutobahn: { [key: string]: string[] } = {
    'Auto Baseline': [
      'version:1#type:MODEL_FORECASTS#publisher:ProbabilisticForecastModel',
      'version:1#type:MODEL_FORECASTS#publisher:ProbabilisticForecastModel#mathModel:p50',
      'version:1#type:MODEL_FORECASTS#publisher:DFS'
    ],
    'Manual Baseline': [
      'version:1#type:MODEL_FORECASTS#publisher:MANUAL'
    ],
    'Central Guidance': [
      'version:1#type:CENTRAL_GUIDANCE#publisher:MANUAL#eventId:dummyEvent'
    ]
};

export function getUserReadablePropertiesByDatasetCid(rawDatasetCid: string) {
    const [versionString, typeString, publisherString, frequencyString] = rawDatasetCid.split('#');
    
      const type = typeString?.split(':')[1] || '';
      const frequency = frequencyString?.split(':')[1] || '';
      const publisher = publisherString?.split(':')[1] || '';
  
      let newDatasetCid = "Test";
      let uploadedBy;
      
      switch (type) {
        case 'ACTUALS':
          newDatasetCid = "Test";
  
          break;
        case 'MODEL_FORECASTS':
          if (publisher === 'DFS') {
            newDatasetCid = "Auto Baseline";
            uploadedBy = "DFS";
          }
          if (publisher === 'MANUAL') {
            newDatasetCid = "Manual Baseline";
          }
          if (publisher === 'ProbabilisticForecastModel') {
            newDatasetCid = "Auto Baseline";
            uploadedBy = "Probabilistic Forecast Model";
          }
          if (publisher === 'RSS') {
            newDatasetCid = "Auto Baseline";
            uploadedBy = "RSS";
          }
  
          break;
        case 'CENTRAL_GUIDANCE':
          newDatasetCid = "Central Guidance";
          break;
        default:
          newDatasetCid = "Test";
          break;
      }
  
    return {
      datasetCid: newDatasetCid,
      uploadedBy,
    }
  }
  
  // Todo: Mostly likely this logic will be moved to AlpsBasecampContent in the future
  export function getUserFriendlyStrings(rawDataPoints: ImportsRawDataPoint[]): ImportsDataPoint[] {
    console.log("rawDataPoints", rawDataPoints)
    return rawDataPoints.map((rawDataPoint) => {
      const {
        scope,
        domainId,
        sourceTime,
        datasetClassificationId,
        status,
        statusReason ,
        lineage,
      } = rawDataPoint;
      const siteId = scope.value;


      console.log('at least here', status)
      if (status === "FAILED") {
      console.log('at least 222', status)

        return {
          site: siteId,
          datasetCid: "N/A",
          metricName: "N/A",
          uploadedBy: "N/A",
          // uploadedAt: new Date(sourceTime).toDateString(),
                uploadedAt: `${format(sourceTime, 'yyyy-MM-dd, HH:mm')}`,
          lastPlanId: "",
          lastPlanDate: "",
          uploadStatus: {
            status: "Failed",
            statusReason: statusReason,
          },
          linkToRawData: {
            url: undefined,
          },
        }
      }
      const [rawDatasetCid] = datasetClassificationId
      const {
        datasetCid: newDatasetCid,
        uploadedBy: newUploadedBy,
      } = getUserReadablePropertiesByDatasetCid(rawDatasetCid)
      let uploadedBy = newUploadedBy || lineage[0].providerId || "N/A";
      let newStatus;

      if (status === "TRANSFORMED") {
        newStatus = "Successful";
      } else if (status === "INGESTED") {
        newStatus = "In Progress";
      } else {
        newStatus = "Failed";
      }

      return  {
        site: siteId,
        datasetCid: newDatasetCid,
        metricName: upperSnakeCaseToTitleCase(domainId),
        uploadedBy: uploadedBy,
        // uploadedAt: new Date(sourceTime).toDateString(),
              uploadedAt: `${format(sourceTime, 'yyyy-MM-dd, HH:mm')}`,
        lastPlanId: "",
        lastPlanDate: "",
        uploadStatus: {
          status: newStatus,
          statusReason: status === "FAILED" ? statusReason : null,
        },
        linkToRawData: {
          url: undefined,
        },
      }
    })
  }

  export function getAutobahnDatasetCidsByUserReadble(datasetCids: string[]): string[] {
    const userReadableDatasetCids: string[] = [];
    
    datasetCids.forEach((datasetCid) => {
        userReadableDatasetCids.push(...userReadableDatasetCidsToAutobahn[datasetCid]);
    })


    return userReadableDatasetCids;
  }